import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Logo, Notification } from 'hds-react';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/logo/customisation",
  "title": "Logo - Customisation"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "customisation",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation`}<a parentName="h2" {...{
        "href": "#customisation",
        "aria-label": "customisation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <Notification type="alert" label="Logo customisation works differently!" className="siteNotification" mdxType="Notification">
  Logo is an older HDS component and therefore its customisation works slightly differently. Instead of the 
  <code>theme</code> property, you need to use the <code>style</code> property. This will be fixed in a future update.
    </Notification>
    <h3 {...{
      "id": "customisation-properties",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation properties`}<a parentName="h3" {...{
        "href": "#customisation-properties",
        "aria-label": "customisation properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`In Core version, you can either use the `}<inlineCode parentName="p">{`style`}</inlineCode>{` or `}<inlineCode parentName="p">{`class`}</inlineCode>{` attributes in the HTML to customise the component.`}</p>
    <p>{`In React version, you can either use the `}<inlineCode parentName="p">{`style`}</inlineCode>{` or `}<inlineCode parentName="p">{`className`}</inlineCode>{` properties to customise the component.`}</p>
    <h3 {...{
      "id": "customisation-example",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation example`}<a parentName="h3" {...{
        "href": "#customisation-example",
        "aria-label": "customisation example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<>
  <Logo language="fi" size="small" style={{ color: 'var(--color-bus)', marginRight: 'var(--spacing-xs)' }} />
  <Logo language="fi" size="medium" style={{ color: 'var(--color-tram)', marginRight: 'var(--spacing-xs)' }} />
  <Logo language="fi" size="large" style={{ color: 'var(--color-metro)' }} />
</>
`}</code></pre>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      